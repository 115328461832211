/// <reference path="../Views/Shared/Site.Master" />


$.fn.parseTemplate = function (data) {
    var str = (this).html();
    var _tmplCache = {}
    var err = "";
    try {
        var func = _tmplCache[str];
        if (!func) {
            var strFunc =
            "var p=[],print=function(){p.push.apply(p,arguments);};" +
                        "with(obj){p.push('" +
            str.replace(/[\r\t\n]/g, " ")
               .replace(/'(?=[^#]*#>)/g, "\t")
               .split("'").join("\\'")
               .split("\t").join("'")
               .replace(/<#=(.+?)#>/g, "',$1,'")
               .split("<#").join("');")
               .split("#>").join("p.push('")
               + "');}return p.join('');";

            //alert(strFunc);
            func = new Function("obj", strFunc);
            _tmplCache[str] = func;
        }
        return func(data);
    } catch (e) { err = e.message; }
    return "< # ERROR: " + err.toString() + " # >";
};

$.fn.hAutoComplete = function (pUrl, pDestSelector, options) {
    //options.LabelCallback func(item) return display row;
    //options.valueCallback func(item) return display text for selection;
    //options.selectCallback  func(event,ui)
    //options.matchOnCode:  Matches the typed text and selects the matching code on the list
    //options.mustBeInList: Leave the value as typed even if no match on code.
    //options.showWatermark: Show or hide watermark.
    options = $.extend({ matchOnCode: true, mustBeInList: true, showWatermark: true, inFocusItem: false }, options);
    var lIsMatchOnCode = options.matchOnCode;
    var lMustBeInList = options.mustBeInList;

    var lData = null;
    var lVal = null;
    var lIsSel = false;
    var lInFocus = false;
    var lInFocusItem = options.inFocusItem;
    var lSelector = $(this);

    if (pUrl == 'destroy') {
        $(this).unbind('focusin');
        $(this).unbind('focusout');
        return $(this).autocomplete('destroy');
    }
    if (options.showWatermark) {
        $(this).watermark();
    }
    $(this).focusin(function () {
        if (!lInFocus) {
            lIsSel = false;
            lVal = $(this).val();
        }
        lInFocus = true;
    });

    $(this).focusout(function (e) {
        if (lInFocusItem) {
            lInFocus = false;
            return;
        }

        var lCurVal = $(this).val();
        if (lCurVal == "") return;

        if (!lIsSel && lIsMatchOnCode) {
            $(lData).each(function (i, e) {
                if (lCurVal.toUpperCase() == e.code.toUpperCase()) {
                    lFuncSelect(e, { item: e });
                    return false;
                }
            });
        }

        if (lMustBeInList || lVal != "")
            $(this).val(lVal);
        lInFocus = false;
    });

    var lFuncSelect = function (event, ui) {
        if (pDestSelector) $(pDestSelector).val(ui.item.item.id);
        lVal = ui.item.value;
        lIsSel = true;
        if (options.selectCallback) return options.selectCallback(event, ui);
    };

    return $(this).autocomplete({
        html: true,
        autoFocus: true,
        source: function (request, response) {
            var opt = options;
            var postData = { code: request.term, quantity: 10 }
            $.extend(true, postData, options.postData);
            $.ajax({
                url: pUrl,
                data: postData,
                dataType: "json",
                type: "POST",
                success: function(data) {
                    var lData = [];
                    data.forEach(function(item) {
                        lData.push({
                            value: item.code,
                            label: '<table class=ui-grid ui-widget-content><tr><td style="width:100px">' + item.code + '</td><td style="width:200px">' + item.name + '</td></tr></table>',
                            code: item.code,
                            item: item,
                            extras: item.extras
                        });
                    });
                    response(lData);
                }
            });
        },
        minLength: 0,
        //focus: function (event, ui) //fires after selection
        //{
        //    this.value = ui.item.value;
        //},
        close: function (event, ui) //fires after selection
        {
            lInFocusItem = options.inFocusItem;
        },
        select: lFuncSelect
    })
        .bind("focus", function () { //shows all list item on focus
            if (this.value == '') {
                $(this).autocomplete("search", "");
            }
        })
    .on("blur", function (event) {
        var autocomplete = $(this).data("autocomplete");
        if (autocomplete == undefined)
            return;

        var matcher = new RegExp("^" + $.ui.autocomplete.escapeRegex($(this).val()) + "$", "i");
        autocomplete.widget().children(".ui-menu-item").each(function () {
            //Check if each autocomplete item is a case-insensitive match on the input
            var item = $(this).data("item.autocomplete");
            if (matcher.test(item.label || item.value || item)) {
                //There was a match, lets stop checking
                autocomplete.selectedItem = item;
                return;
            }
        });
        //if there was a match trigger the select event on that match
        if (autocomplete.selectedItem) {
            autocomplete._trigger("select", event, {
                item: autocomplete.selectedItem
            });
            //there was no match, clear the input
        } else {
            $(this).val('');
        }
    });
};


$.fn.htAutoComplete = function (pUrl, pDestSelector, options) {
    //options.LabelCallback func(item) return display row;
    //options.valueCallback func(item) return display text for selection;
    //options.selectCallback  func(event,ui)
    //options.matchOnCode:  Matches the typed text and selects the matching code on the list
    //options.mustBeInList: Leave the value as typed even if no match on code.
    //options.showWatermark: Show or hide watermark.
    //options.selectItemHtml: func(item)  returns select item html for autocomplete, filled with actiual data
    options = $.extend({ matchOnCode: true, mustBeInList: true, showWatermark: true, inFocusItem: false }, options);
    var lIsMatchOnCode = options.matchOnCode;
    var lMustBeInList = options.mustBeInList;

    var lData = null;
    var lVal = null;
    var lIsSel = false;
    var lInFocus = false;
    var lInFocusItem = options.inFocusItem;
    var lSelector = $(this);

    if (pUrl == 'destroy') {
        $(this).unbind('focusin');
        $(this).unbind('focusout');
        return $(this).autocomplete('destroy');
    }
    if (options.showWatermark) {
        $(this).watermark();
    }
    $(this).focusin(function () {
        if (!lInFocus) {
            lIsSel = false;
            lVal = $(this).val();
        }
        lInFocus = true;
    });

    $(this).focusout(function (e) {
        if (lInFocusItem) {
            lInFocus = false;
            return;
        }

        var lCurVal = $(this).val();
        if (lCurVal == "") return;

        if (!lIsSel && lIsMatchOnCode) {
            $(lData).each(function (i, e) {
                if (lCurVal.toUpperCase() == e.code.toUpperCase()) {
                    lFuncSelect(e, { item: e });
                    return false;
                }
            });
        }

        if (lMustBeInList || lVal != "")
            $(this).val(lVal);
        lInFocus = false;
    });

    var lFuncSelect = function (event, ui) {
        if (pDestSelector) $(pDestSelector).val(ui.item.item.id);
        lVal = ui.item.value;
        lIsSel = true;
        if (options.selectCallback) return options.selectCallback(event, ui);
    };

    var getItemHtml = function (item) {
        if (options.selectItemHtml)
            return options.selectItemHtml(item);

        var html = '<table class=ui-grid ui-widget-content><tr><td title="' + item.code + '" style="width:100px">' + item.code + '</td>';
        if (item.name)
            html += '<td title="' + item.name + '" style="width:200px">' + item.name + '</td>';
        html += '</tr></table>';
        return html;
    };

    var previousRequest;

    return $(this).autocomplete({
        delay: 500,
        html: true,
        open: function (event, ui) {
            var widthAutocomplete = $(this).outerWidth();
            $(".ui-autocomplete").css("width", widthAutocomplete);
        },
        appendTo: "body",//Seems to make sense for all 'autocomplete' elements
        source: function (request, response) {
            if (previousRequest) {
                previousRequest.abort();
            }

            var postData = { code: request.term, quantity: 10 }
            $.extend(true, postData, options.postData);

            previousRequest = $.post(pUrl, postData, null, "json")
              .done(function (data) {
                  if (!Array.isArray(data)) {
                      console.error("Expected array, but \"" + pUrl + "\" returned other object type")
                      response([]);
                  }

                  var lData = data.map(function (item) {
                      var html = getItemHtml(item);

                      return {
                          value: item.code,
                          label: html,
                          code: item.code,
                          item: item,
                          extras: item.extras
                      };
                  });
                  response(lData);
              })
              .always(function () {
                  previousRequest = undefined;
              })
        },
        minLength: 0,
        focus: function (event, ui) //fires after selection
        {
            this.value = ui.item.value;
        },
        close: function (event, ui) //fires after selection
        {
            lInFocusItem = options.inFocusItem;
        },
        select: lFuncSelect
    }).bind("focus", function () {//shows all list item on focus
        if (this.value == '' || this.value == ' ') {
            $(this).autocomplete("search", "");
        }
    });

};

$.fn.htAutoCompleteDisplayCode = function (pUrl, pDestSelector, options) {
    //options.LabelCallback func(item) return display row;
    //options.valueCallback func(item) return display text for selection;
    //options.selectCallback  func(event,ui)
    //options.matchOnCode:  Matches the typed text and selects the matching code on the list
    //options.mustBeInList: Leave the value as typed even if no match on code.
    //options.showWatermark: Show or hide watermark.
    options = $.extend({ matchOnCode: true, mustBeInList: true, showWatermark: true, inFocusItem: false }, options);
    var lIsMatchOnCode = options.matchOnCode;
    var lMustBeInList = options.mustBeInList;

    var lData = null;
    var lVal = null;
    var lIsSel = false;
    var lInFocus = false;
    var lInFocusItem = options.inFocusItem;
    var lSelector = $(this);

    if (pUrl == 'destroy') {
        $(this).unbind('focusin');
        $(this).unbind('focusout');
        return $(this).autocomplete('destroy');
    }
    if (options.showWatermark) {
        $(this).watermark();
    }
    $(this).focusin(function () {
        if (!lInFocus) {
            lIsSel = false;
            lVal = $(this).val();
        }
        lInFocus = true;
    });

    $(this).focusout(function (e) {
        if (lInFocusItem) {
            lInFocus = false;
            return;
        }

        var lCurVal = $(this).val();
        if (lCurVal == "") return;

        if (!lIsSel && lIsMatchOnCode) {
            $(lData).each(function (i, e) {
                if (lCurVal.toUpperCase() == e.code.toUpperCase()) {
                    lFuncSelect(e, { item: e });
                    return false;
                }
            });
        }

        if (lMustBeInList || lVal != "")
            $(this).val(lVal);
        lInFocus = false;
    });

    var lFuncSelect = function (event, ui) {
        if (pDestSelector) $(pDestSelector).val(ui.item.item.id);
        lVal = ui.item.code;
        lIsSel = true;
        if (options.selectCallback) return options.selectCallback(event, ui);
    };

    return $(this).autocomplete({
        html: true,
        source: function (request, response) {
            var opt = options;
            var postData = { code: request.term, quantity: 10 }
            $.extend(true, postData, options.postData);
            $.ajax({
                url: pUrl,
                data: postData,
                dataType: "json",
                type: "POST",
                success: function (data) {
                    var lData = [];
                    data.forEach(function (item) {
                        lData.push({
                            value: item.code,
                            label: '<table class=ui-grid ui-widget-content><tr><td style="width:100px">' + item.code + '</td><td style="width:200px">' + item.name + '</td></tr></table>',
                            code: item.code,
                            item: item,
                            extras: item.extras
                        });
                    });
                    response(lData);
                }
            })
        },
        minLength: 0,
        focus: function (event, ui) //fires after selection
        {
            this.value = ui.item.code;
        },
        close: function (event, ui) //fires after selection
        {
            lInFocusItem = options.inFocusItem;
        },
        select: lFuncSelect
    }).bind("focus", function () {//shows all list item on focus
        if (this.value == '') {
            $(this).autocomplete("search", "");
        }
    });

};

$.fn.scrollWidth = function (val) {
    if (!this[0]) return this;

    return val != undefined ?
    // Set the scrollWidth
    this.each(function () {
        this == window || this == document ?
    window.scrollWidth(val) :
    this['scrollWidth'] = val;
    }) :
        // Return the scrollWidth
    this[0] == window || this[0] == document ?
    self['scrollWidth'] ||
    $.boxModel && document.documentElement['scrollWidth'] ||
    document.body['scrollWidth'] :
    this[0]['scrollWidth'];
};

$.textMetrics = function (el) {
    var div = document.createElement('div');
    $(div).css({
        overflow: 'hidden'
    });

    var lHtml = $(el).html();
    $(div).html(lHtml);
    $(el).html("");
    $(el).append($(div));

    var ret = {
        height: $(div).innerHeight(),
        width: $(div).outerWidth(),
        scrollWidth: $(div).scrollWidth()
    };

    $(div).remove();
    $(el).html(lHtml);
    return ret;
};

$.fn.htAutoTooltip = function (selector) {

    $(this).each(function () { if ($(this).is("[htAutoTooltip]")) $(this).off("mouseover", $(this).attr("htAutoTooltip")); })

    if (!selector) selector = "td";

    var lHandler = function (e) {
        var ej = $(e.target);
        var el = ej.get(0);
        if (ej.length == 1 && ej.is(selector) && ej.children().length == 0 && (ej.attr("isAutoTooltip") || !ej.attr("title"))) {
            var ltm = $.textMetrics(ej);
            if (ltm.scrollWidth > ltm.width) {
                ej.attr("title", ej.text());
                ej.attr("isAutoTooltip", true);
            }
            else if (ej.attr("isAutoTooltip"))
                ej.removeAttr("title");
        }
    }

    $(this).attr("htAutoTooltip", lHandler);
    return $(this).on("mouseover", lHandler);
};

// sortable grid
(function ($) {

    $.fn.htgrid = function (filter, options) {
        // support multiple elements
        if (this.length == 0) return;

        if (this.length > 1){
            this.each(function() { $(this).htgrid(filter, options) });
            return this;
        }

        var f = filter;

        // private variables
        var main_opts = $.extend(true, {}, $.fn.htgrid.defaults, options);
        // if metadata is present, extend main_opts, otherwise just use main_opts
        var opts = $.meta ? $.extend({}, main_opts, $this.data()) : main_opts;
        // $this = jQuery handle to header table
        var $this = $(this), self = this, $header = $(this);

        var headers = [];
        var gridCols = [];
        var footerCols = [];
        var $pager;

        // get a handle to the header container
        opts.headerContainerSelector = opts.headerContainerSelector ? opts.headerContainerSelector : $header.parent();
        var $headerContainer = $(opts.headerContainerSelector);

        // get a handle to the grid table
        var baseClass = new String($this.attr('class')).match(/([^ ]*)Header/)[1];

        opts.gridSelector = opts.gridSelector ? opts.gridSelctor : '.' + baseClass + 'Grid';
        var $grid = $(opts.gridSelector);

        $grid.find('col').each(function (i, v) {
            gridCols[i] = { width: $(this).css('width').replace('px', ''), el: $(this) };
        });

        // get a handle to the grid container
        opts.gridContainerSelector = opts.gridContainerSelector ? opts.gridContainerSelector : $grid.parent();
        var $gridContainer = $(opts.gridContainerSelector);

        if (opts.hasfooter) {
            // get a handle to the footer table
            opts.footerSelector = opts.footerSelector ? opts.footerSelector : '.' + baseClass + 'Footer';
            var $footer = $(opts.footerSelector);

            $footer.find('col').each(function (i, v) {
                footerCols[i] = { width: $(this).width(), el: $(this) };
            });

            // get a handle to the footer container
            opts.footerContainerSelector = opts.footerContainerSelector ? opts.footerContainerSelector : $footer.parent();
            var $footerContainer = $(opts.footerContainerSelector);
        }

        // set default callbacks
        opts.beforeLoad = $.isFunction(opts.beforeLoad) ? opts.beforeLoad : (function () { return true; } );
        opts.onLoad = $.isFunction(opts.onLoad) ? opts.onLoad : (function () { return true; } );
        opts.afterLoad = $.isFunction(opts.afterLoad) ? opts.afterLoad : (function () { return true; } );
        opts.onRowSelected = $.isFunction(opts.onRowSelected) ? opts.onRowSelected : (function () { return true; } );

        var bindFilter = function () {
            // clear sort indicators
            $this.find('th.' + opts.sortclass).each(function () {
                $(this).removeClass(opts.ascendingsortclass + ' ' + opts.descendingsortclass).addClass(opts.unsortedclass);
            });
            if (f != null || f != undefined) {
                if (f[opts.sortexpproperty] != null || f[opts.sortexpproperty] != undefined) {
                    var sortExps = f[opts.sortexpproperty].split(',');
                    for(var i=0, len=sortExps.length; i < len; i++){
                        var sortParts = sortExps[i].split(" ");
                        if (sortParts.length > 1) {
                            var col = sortParts[0].trim();
                            var dir = sortParts[1].trim();
                            $this.find('th.' + opts.sortclass).each(function () {
                                if ($(this).attr(opts.sortpropertyattr) == col) {
                                    $(this).attr('direction', dir);
                                    if (dir == "asc") {
                                        $(this).attr('direction', 'asc').removeClass(opts.unsortedclass + ' ' + opts.descendingsortclass).addClass(opts.ascendingsortclass);
                                    }
                                    else if (dir == "desc") {
                                        $(this).attr('direction', 'desc').removeClass(opts.unsortedclass + ' ' + opts.ascendingsortclass).addClass(opts.descendingsortclass);
                                    }
                                }
                            });
                        }
                    }
                }
            }
        };

        var dragStart = function(i,x) {
	        self.resizing = { idx: i, startX: x};
	        $this.css('cursor', "e-resize");

        };

        var dragMove = function(e) {
	        if (self.resizing) {
                var x = e.clientX;
		        var diff = x-self.resizing.startX;
		        var h = headers[self.resizing.idx];
                var gc = gridCols[self.resizing.idx];
                var gWidth = $grid.width();
                var gcWidth = $gridContainer.width();
                if (opts.hasfooter) {
                    var fc = footerCols[self.resizing.idx];
                    var fWidth = $footer.width();
                    var fcWidth = $footerContainer.width();
                }
                var thisWidth = $this.width();
		        var newWidth = h.el.width() + diff;
                //;;;console.log('oldWidth: ' + h.el.width() + ' - diff: ' + diff + ' - newWidth: ' + newWidth);
		        if (newWidth > 50) {
                    self.resizing.startX = self.resizing.startX + diff;
			        h.el.width(newWidth);
			        h.newWidth = newWidth;
			        gc.el.width(newWidth);
                    gc.newWidth = newWidth;
			        self.newWidth = self.width+diff;
			        //$grid.width(gWidth + diff);
			        $gridContainer.width(gcWidth + diff);
                    if (opts.hasfooter) {
			            fc.el.width(newWidth);
                        fc.newWidth = newWidth;
                        //$footer.width(fWidth + diff);
                        //$footerContainer.width(fcWidth + diff);
                    }
                    //$this.width(thisWidth + diff);
		        }
	        }
        };

        var dragEnd = function() {
	        $this.css('cursor', "default");
	        if (self.resizing) {
		        var idx = self.resizing.idx;
		        headers[idx].width = headers[idx].newWidth;
		        self.width = self.newWidth;
		        self.resizing = false;
	        }
        };

        var makeRowSelectable = function($datagrid, multi) {
            // no row hover because IE8 is a slow p.o.s. browser.
//            $datagrid.mouseover(
//                function(e) {
//                    $(e.target).parents('tr:first').find('td').addClass('ui-state-hover');
//                }).mouseout(
//                function(e) {
//                    $(e.target).parents('tr:first').find('td').removeClass('ui-state-hover');
//                }
//            );
            $datagrid.css('cursor', 'pointer');
            $datagrid.click(function(e) {
                if (multi) {
                    $(e.target).parents('tr:first').find('td').toggleClass('ui-state-selected');
                }
                else {
                    $datagrid.find('td').removeClass('ui-state-selected');
                    $(e.target).parents('tr:first').find('td').addClass('ui-state-selected');
                }
                if (opts.onRowSelected.call($(e.target).parents('tr:first'))) {
                    // if has first column checkbox
                    var $cb = $(e.target).parents('tr:first').find('td:first input[type=checkbox]');
                    if($cb.length > 0) {
                        if ($(e.target).parents('tr:first').find('td.ui-state-selected').length > 0) {
                            $cb.attr('checked', 'checked');
                        }
                        else {
                            $cb.removeAttr('checked');
                        }
                    }
                }
            });
        };

        var resizeScrollContainerWidth = function ($datagrid) {
            var tw = gridWidth($datagrid), sw = Site.Screen.scrollbarWidth, w;
            if (tw == 0 || tw == 100 || (tw == 100 + sw)) {
                $datagrid.appendTo('body');
                tw = $datagrid.width();
                $datagrid.appendTo($gridContainer);
            }
            $gridContainer.width(tw + sw + 1);
        };

        var gridWidth = function ($datagrid) {
            var tw = $datagrid.width();
            if (tw == 0 || tw == 100) {
                $datagrid.appendTo('body');
                tw = $datagrid.width();
                $datagrid.appendTo($gridContainer);
            }
            return tw;
        };

        var gridHeadWidth = function ($headgrid) {
            var tw = $headgrid.find('thead').width();
             if (tw == 0 || tw == 100) {
                $headgrid.appendTo('body');
                tw = $headgrid.width();
                $headgrid.appendTo($headerContainer);
            }
           if (tw == null || tw == 0 || tw == 100) { return gridWidth($grid); }
            return tw;
        };

        var buildPager = function() {
            // build pager elements
            var pClass = baseClass + 'Pager';
            var pSelector = '.' + pClass;
            var wClass = pClass + 'Wrapper';
            if ($(pSelector).length == 0) {
                // create wrapper div
                var $pW = $('<div></div>');
                $pW.addClass(wClass);
                // create container
                var $pC = $('<div></div>');
                $pC.addClass(pClass + ' ui-helper-clearfix section3');
                //;;;console.log(gridHeadWidth($this));
                //;;;console.log($this);
                $pC.width(gridHeadWidth($header));
                //$pC.css('border', '1px solid gray');
                // create left col
                var $pL = $('<div class="pager-leftcol">&nbsp;Viewing&nbsp;<span class="htgrid-startRecord"></span>&nbsp;-&nbsp;<span class="htgrid-endRecord"></span>&nbsp;of&nbsp;<span class="htgrid-RecordCountResultSet"></span>&nbsp;in&nbsp;<span class="htgrid-totPages"></span></div>');
                //$pL.css('padding', '3px');
                //$pL.css('margin', 'auto');
                // create right col
                var $pR = $('<div class="pager-rightcol"><span class="htgrid-page-size" style="margin-right:25px;"></span><span class="htgrid-page-links"></span></div>');
                //$pR.css('padding', '3px');
                //$pR.css('margin', 'auto');

                // assemble and insert into markup
                $pC.append($pL);
                $pC.append($pR);
                $pW.append($pC);
                // insertAfter(footer or grid)
                if (opts.hasfooter) {
                    $pW.insertAfter($footerContainer);
                }
                else {
                    $pW.insertAfter($gridContainer);
                }
            }
            $(pSelector).removeClass('htgrid-loading');
            // empty page links
            $(pSelector).find('.htgrid-page-links').html('');
            // calculate first page number and last page number for page links
            var pageSize = parseInt(f[opts.pagesizeproperty],10),pageNum = parseInt(f[opts.pagenumberproperty],10),recordCnt = parseInt(f[opts.countresultsetproperty],10);
            var lower=lowerPageLnkNum(pageNum), end=upperPageLnkNum(pageNum, pageSize, recordCnt);
            var tps = totPages(pageSize, recordCnt);
            //;;;console.log('-lower: ' + lower + ' -current: ' + f[opts.pagenumberproperty] + ' -end: ' + end + ' -tot: ' + tps);
            if (end - lower < 8) {
                if (pageNum - lower < 4) {
                    //;;;console.log(4 - (pageNum - lower));
                    end = end + (4 - (pageNum - lower));
                    if (end > tps) { end = tps; }
                }
                if (tps - pageNum < 4) {
                    //;;;console.log(4 - (tps - pageNum));
                    lower = lower - (4 - (tps - pageNum));
                    if (lower < 1) { lower = 1; }
                }
            }
            //;;;console.log('-lower: ' + lower + ' -current: ' + pageNum + ' -end: ' + end + ' -tot: ' + tps);
            // add first page link
          var first = (pageNum != 1) ? '<button class="htgrid-page-link htgrid-page-link-start" page="1" ><i class="fa fa-fast-backward" aria-hidden="true"></i></button>' : '<button class="htgrid-page-link htgrid-page-link-start ui-state-disabled" page="1" ><i class="fa fa-fast-backward" aria-hidden="true"></i></button>';
            $(pSelector).find('.htgrid-page-links').append(first);
            // add previous page link
          var pre = (pageNum > lower) ? '<button class="htgrid-page-link htgrid-page-link-prev" page="' + (pageNum - 1) + '" ><i class="fa fa-step-backward" aria-hidden="true"></i></button>' : '<button class="htgrid-page-link htgrid-page-link-prev ui-state-disabled" page="1" ><i class="fa fa-step-backward" aria-hidden="true"></i></button>';
            $(pSelector).find('.htgrid-page-links').append(pre);
            // add page links
            for (var p = lower; p <= end; p++) {
                if (p == pageNum) {
                    $(pSelector).find('.htgrid-page-links').append('<button class="htgrid-page-link ui-state-disabled" page="' + p + '" >' + p + '</button>');
                }
                else {
                    $(pSelector).find('.htgrid-page-links').append('<button class="htgrid-page-link" page="' + p + '" >' + p + '</button>');
                }
            }
            // add next page link
          var nxt = (pageNum != end) ? '<button class="htgrid-page-link htgrid-page-link-next" page="' + (pageNum + 1) + '" ><i class="fa fa-step-forward" aria-hidden="true"></i></button>' : '<button class="htgrid-page-link htgrid-page-link-next ui-state-disabled" page="' + tps + '" ><i class="fa fa-step-forward" aria-hidden="true"></i></button>';
            $(pSelector).find('.htgrid-page-links').append(nxt);
            // add last page link
          var last = (pageNum != tps) ? '<button class="htgrid-page-link htgrid-page-link-end" page="' + tps + '" ><i class="fa fa-fast-forward" aria-hidden="true"></i></button>' : '<button class="htgrid-page-link htgrid-page-link-end ui-state-disabled" page="' + tps + '" ><i class="fa fa-fast-forward" aria-hidden="true"></i></button>';
            $(pSelector).find('.htgrid-page-links').append(last);

            // add start, end and total record number + total pages
            $(pSelector).find('.htgrid-startRecord').html(startRecord(pageSize, pageNum, recordCnt));
            $(pSelector).find('.htgrid-endRecord').html(endRecord(pageSize, pageNum, recordCnt));
            var s = (recordCnt == 0 || recordCnt > 1) ? ' Items' : ' Item';
            $(pSelector).find('.htgrid-RecordCountResultSet').html(f[opts.countresultsetproperty] + s);
            // total pages
            var t = (tps == 0 || tps > 1) ? ' Pages' : ' Page';
            $(pSelector).find('.htgrid-totPages').html(tps.toString() + t);

            $(pSelector).find('.htgrid-page-link').each(function () {
                var i = '';
                if ($(this).hasClass('htgrid-page-link-start')) {
                    i = 'ui-icon-seek-start';
                }
                if ($(this).hasClass('htgrid-page-link-prev')) {
                    i = 'ui-icon-seek-prev';
                }
                if ($(this).hasClass('htgrid-page-link-next')) {
                    i = 'ui-icon-seek-next';
                }
                if ($(this).hasClass('htgrid-page-link-end')) {
                    i = 'ui-icon-seek-end';
                }
                var d = false;
                if ($(this).hasClass('ui-state-disabled')) {
                    d = true;
                }
                var o = {
                    text: false,
                    disabled: d,
                    icons: { primary: i }
                };
                $(this).button(o);
            });
            $(pSelector).find('.htgrid-page-link').click(function (e) {
                if ($(this).hasClass('ui-state-disabled')) {
                    return;
                }
                f[opts.pagenumberproperty] = $(this).attr('page');
                self.loadData(f);
            });
            // add page size select
            $(pSelector).find('.htgrid-page-size').html('');
            $(pSelector).find('.htgrid-page-size').append('<label>Page size:&nbsp;</label><select name="'+ opts.pagesizeproperty +'"><option>10</option><option>50</option><option>100</option><option>200</option></select>');
            $(pSelector).find('.htgrid-page-size select[name='+ opts.pagesizeproperty +']').val(f[opts.pagesizeproperty]);
            $(pSelector).find('.htgrid-page-size select[name='+ opts.pagesizeproperty +']').off().change(function () {
                f[opts.pagesizeproperty] = $(this).val();
                self.loadData(f);
            });

            $pager = $(pSelector);
        };

        var totPages = function (pageSize, tot) {
            if (isNaN(pageSize) || isNaN(tot)) {
                return 1;
            }
            if (tot == 0) { return 1; }
            return Math.ceil(tot / pageSize);
        };

        var startRecord = function (pageSize, pageNum, tot) {
            if (isNaN(pageSize) || isNaN(pageNum) || isNaN(tot)) {
                return 'unknown';
            }
            if (tot == 0) { return 0; }
            return ((pageNum - 1) * pageSize) + 1;
        };

        var endRecord = function (pageSize, pageNum, tot) {
            if (isNaN(pageSize) || isNaN(pageNum) || isNaN(tot)) {
                return 'unknown';
            }
            var t = pageNum * pageSize;
            if (t > tot) { t = tot; }
            return t;
        };

        var lowerPageLnkNum = function (pageNum) {
            if (isNaN(pageNum)) {
                return 1;
            }
            var t = pageNum - 4;
            if (t < 1) { t = 1; }
            return t;
        };

        var upperPageLnkNum = function (pageNum, pageSize, tot) {
            if (isNaN(pageNum) || isNaN(pageSize) || isNaN(tot)) { return 1; }
            var t = pageNum + 4;
            var tt = totPages(pageSize, tot);
            if (t > tt) { t = tt; }
            return t;
        };


        // public methods
        this.init = function () {

            if (opts.htautotooltip) {
                if ($grid.hasClass('fixedCols2')) {
                    $grid.find('td > span').htAutoTooltip('span');
                }
                else {
                    $grid.find('td').htAutoTooltip('td');
                }
                $grid.find('td > a').htAutoTooltip('a');
            }

            $this.find('col').each(function (i, v) {
                headers[i] = { width: $(this).css('width').replace('px', ''), el: $(this) };
            });

            $this.find('tr:last th').each(function (i, v) {
                if ($(this).attr(opts.sortpropertyattr))
                    $(this).addClass(opts.sortclass + ' ' + opts.unsortedclass);

                if (opts.resizeable) {
                    var res = document.createElement("span");
				    $(res).html("xx");
                    $(res).css('height','100%')
                        .css('width','5px')
                        .css('cursor','e-resize')
                        .css('float','right')
                        .css('margin-right','-3px')
                        .css('border','1px solid red');
				    $(res).mousedown(
					    function (e) {
						    dragStart(i,e.clientX);
						    return false;
					    }
				    )
				    $(this).css("width",$(this).width()+"px").append(res);
                }
            });
            $this.find('th.' + opts.sortclass).click(function (e) {
                var $th = $(this);
                var colName = $th.attr(opts.sortpropertyattr);
                var colDir = $th.attr('direction');
                var NcolDir;
                if (colDir == 'asc') {
                    colDir = 'desc';
                    NcolDir = 'asc';
                }
                else {
                    colDir = 'asc';
                    NcolDir = 'desc';
                }
                var sExp = colName + ' ' + colDir;
                if (Site.isCtrlPressed(e)) {
                    var sortExps = f[opts.sortexpproperty].split(',');
                    if (sortExps.length > 0) {
                        var inA = false;
                        var wDir = false;
                        for (var i = 0, len = sortExps.length; i < len; i++) {
                            if (sortExps[i].indexOf(colName) > -1) {
                                inA = true;
                                if (sortExps[i].indexOf(colDir) < 0) {
                                    wDir = true;
                                }
                            }
                        }
                        if (inA == true && wDir == true) {
                            f[opts.sortexpproperty] = f[opts.sortexpproperty].replace(colName + ' ' + NcolDir, sExp);
                        }
                        if (inA == false) {
                            f[opts.sortexpproperty] += ',' + sExp;
                        }
                    }
                    else {
                        f[opts.sortexpproperty] = sExp;
                    }
                }
                else {
                    f[opts.sortexpproperty] = sExp;
                }
                $th.attr('direction',colDir);
                self.loadData(f);
            });

            if(opts.resizeable) {
                $this.mousemove(function (e) {dragMove(e);});
                $(document).mouseup(function (e) {dragEnd();})
            }

            if (opts.rowselectable || opts.multirowselectable) {
                makeRowSelectable($grid, opts.multirowselectable);
            }

            resizeScrollContainerWidth($grid);
            bindFilter();

            if (f[opts.usepagingproperty]) {
                buildPager();
            }

            return this;
        };

        this.loadData = function (filter) {
            f = filter;
            if (opts.rowselectable || opts.multirowselectable) {
                var rowIds = self.getSelectedIds();
            }
            if (opts.beforeLoad.call(this)){
                if (f[opts.usepagingproperty]) {
                    $pager.addClass('htgrid-loading');
                }
                for (i in f) {
                    if (f[i] === null || f[i] === undefined || f[i].length == 0) {
                        delete f[i];
                    }
                }
                $.post(opts.loadUrl, f, function (data, msg, request) {
                    if (opts.onLoad.call(this, data, msg, request)){
                        $gridContainer.html(data.grid);
                        $grid = $(opts.gridSelector);
                        $grid.find('col').each(function (i, v) {
                            gridCols[i] = { width: $(this).css('width').replace('px', ''), el: $(this) };
                        });
                        if (opts.hasfooter) {
                            $footerContainer.html(data.footer);
                        }
                        if (opts.rowselectable || opts.multirowselectable) {
                            makeRowSelectable($grid, opts.multirowselectable);
                            for (var i = 0, len = rowIds.length; i < len; ++i) {
                                $grid.find('input[value=' + rowIds[i] + ']').click();
                            }
                        }
                        if (opts.htautotooltip) {
                            if ($grid.hasClass('fixedCols2')) {
                                $grid.find('td > span').htAutoTooltip('span');
                            }
                            else {
                                $grid.find('td').htAutoTooltip('td');
                            }
                            $grid.find('td > a').htAutoTooltip('a');
                        }
                        if (opts.resizeable) {
                            for (var i = 0, len = headers.length; i < len; i++) {
                                self.setColWidth(i, headers[i].el.width());
                            }
                        }
                        resizeScrollContainerWidth($grid);
                        f = $.evalJSON(data.filter);
                        bindFilter();
                        if (f[opts.usepagingproperty]) {
                            buildPager();
                            $pager.removeClass('htgrid-loading');
                        }
                    }
                opts.afterLoad.call(this);
                });
            }
            return this;
        };

        this.setColWidth = function(colIndex, width){
		    var h = headers[colIndex];
            var gc = gridCols[colIndex];
            //;;;console.log(gc.width);
            var gWidth = $grid.width();
            //;;;console.log($grid.width());
            var gcWidth = $gridContainer.width();
            if (opts.hasfooter) {
                var fc = footerCols[colIndex];
                var fWidth = $footer.width();
                var fcWidth = $footerContainer.width();
            }
            var thisWidth = $this.width();
		    var newWidth = width;
            var hdiff = width - h.width;
            var gdiff = width - gc.width;
            //;;;console.log(gdiff);

			h.el.width(width);
			h.width = width;
			gc.el.width(width);
            gc.width = width;
			self.newWidth = self.width + hdiff;
            //;;;console.log('grid width ' + $grid.width());
            //;;;console.log('gWidth ' + gWidth);
            //;;;console.log('gdiff ' + gdiff);
            //;;;console.log('new grid width ' + (gWidth + gdiff));
			$grid.width(gWidth + gdiff);
            //;;;console.log('grid width ' + $grid.width());
			$gridContainer.width($grid.width() + Site.Screen.scrollbarWidth);
            if (opts.hasfooter) {
			    fc.el.width(width);
                fc.width = width;
                $footer.width(fWidth + hdiff);
                $footerContainer.width(fcWidth + hdiff);
            }
            $this.width(thisWidth + hdiff);

            return this;
        };

        this.setFilter = function (pFilter) { f = filter = pFilter; return this; };

        this.getSelectedIds = function () { return $grid.find('.ui-state-selected input[type=hidden]').map(function() { return $(this).val(); }).get(); };

        // not tested
        this.extendOptions = function (options) {
            opts = $.extend(true, {}, opts, options);
            return this;
        };
        this.getOptions = function () { return opts; };
        // end not tested

        this.exportToExcel = function(p){
            $('body').find('#htgrid-export-iframe').remove();
            $('body').find('#htgrid-export-form').remove();

                    var ifr = $('<iframe id="htgrid-export-iframe" name="download_xls" width="0" height="0" scrolling="no" frameborder="0"></iframe>');
                    $('body').append(ifr);
                    //var frm = $('<form method="post" action="JobSubmit/HTMLTableToExcel"></form>');
                    var frm = $('<form id="htgrid-export-form" method="post" action="/JobSubmit/HTMLTableToExcel" target="download_xls"></form>');
                    $('body').append(frm);
                    var nm = $('<input type="hidden" name="pFileName" value="' + baseClass + '" />');
                    var ti = $('<input type="hidden" name="pHTML" />');

            // get all data from server by turning off paging and making request
            if(p == true){
                var nf = jQuery.extend(true, {}, f);
                nf[opts.usepagingproperty] = false;
                $.post(opts.loadUrl, nf, function (data, msg, request) {
//                    var ifr = $('<iframe id="htgrid-export-iframe" name="download_xls" width="0" height="0" scrolling="no" frameborder="0"></iframe>');
//                    $('body').append(ifr);
//                    //var frm = $('<form method="post" action="JobSubmit/HTMLTableToExcel"></form>');
//                    var frm = $('<form id="htgrid-export-form" method="post" action="JobSubmit/HTMLTableToExcel" target="download_xls"></form>');
//                    $('body').append(frm);
//                    var nm = $('<input type="hidden" name="pFileName" value="' + baseClass + '" />');
//                    var ti = $('<input type="hidden" name="pHTML" />');

                    var d = $('<div></div>');
                    var t = $('<div></div>').html(data.grid).find('table');
                    var h = $this.find('tr').clone(true);
                    h.insertBefore(t.find('tr :first').parent());
                    d.append(t);
                    //            d.find('colgroup').remove();
                    //            d.find('*[pkey]').each(function(i){
                    //                $(this).removeAttr('pkey');
                    //            });
                    //            d.find('*[class]').each(function(i){
                    //                $(this).removeAttr('class');
                    //            });
                    //            d.find('*[style]').each(function(i){
                    //                $(this).removeAttr('style');
                    //            });
                    //            d.find('*[sortproperty]').each(function(i){
                    //                $(this).removeAttr('sortproperty');
                    //            });
                    var s = d.html().replace(/&/g, '&amp;')
                                    .replace(/"/g, '&quot;')
                                    .replace(/'/g, '&#39;')
                                    .replace(/</g, '&lt;')
                                    .replace(/>/g, '&gt;');
                    ti.val(s);
                    frm.append(nm);
                    frm.append(ti);
                    frm.submit();
                });
            }
            else {
//                var ifr = $('<iframe id="htgrid-export-iframe" name="download_xls" width="0" height="0" scrolling="no" frameborder="0"></iframe>');
//                $('body').append(ifr);
//                //var frm = $('<form method="post" action="JobSubmit/HTMLTableToExcel"></form>');
//                var frm = $('<form id="htgrid-export-form" method="post" action="JobSubmit/HTMLTableToExcel" target="download_xls"></form>');
//                $('body').append(frm);
//                var nm = $('<input type="hidden" name="pFileName" />');
//                var ti = $('<input type="hidden" name="pHTML" />');

                var d = $('<div></div>');
                var t = $gridContainer.find('table').clone(true);
                var h = $this.find('tr').clone(true);
                h.insertBefore(t.find('tr :first').parent());
                d.append(t);
                //            d.find('colgroup').remove();
                //            d.find('*[pkey]').each(function(i){
                //                $(this).removeAttr('pkey');
                //            });
                //            d.find('*[class]').each(function(i){
                //                $(this).removeAttr('class');
                //            });
                //            d.find('*[style]').each(function(i){
                //                $(this).removeAttr('style');
                //            });
                //            d.find('*[sortproperty]').each(function(i){
                //                $(this).removeAttr('sortproperty');
                //            });
                var s = d.html().replace(/&/g, '&amp;')
                                .replace(/"/g, '&quot;')
                                .replace(/'/g, '&#39;')
                                .replace(/</g, '&lt;')
                                .replace(/>/g, '&gt;');
                ti.val(s);
                frm.append(nm);
                frm.append(ti);
                frm.submit();
            }
            return this;
        };

        return this.init();
    };
    //
    // plugin defaults
    //
    $.fn.htgrid.defaults = {
        sortpropertyattr: 'sortproperty',
        sortclass: 'sort',
        unsortedclass: 'unsorted',
        ascendingsortclass: 'sorted-asc',
        descendingsortclass: 'sorted-dsc',
        htautotooltip: true,
        sortcolproperty: 'sortCol',
        sortdirproperty: 'sortDir',
        sortexpproperty: 'sortExp',
        pagesizeproperty: 'RequestedPageSize',
        pagenumberproperty: 'RequestedPageNumber',
        countresultsetproperty: 'RecordCountResultSet',
        countdatascopeproperty: 'RecordCountDataScope',
        hasfooter: false,
        resizeable: false,
        rowselectable: false,
        multirowselectable: false,
        usepaging: false,
        usepagingproperty: 'UsePaging'
    };
})(jQuery);

$.fn.htSelectionClear = function (isDestroy) {

    $(this).each(function () {
        if ($(this).is("[htSelectionClear]")) {
            var h = $(this).attr("htSelectionClear")
            $(this).off("mousedown", h.mousedown);
            $(this).off("click", h.click);
            $(this).off("dblclick", h.dblclick);
        }
    })

    if (isDestroy)
        return $(this);
    else {
        var lClear = true;
        var lHandlers = { mousedown: function () { lClear = true; $(this).one('mousemove', function () { lClear = false; }); }
                        , click: function () { if (lClear) _clearSelection(); }
                        , dblclick: function () { _clearSelection(); }
        };

        $(this).attr("htSelectionClear", lHandlers);
        return $(this).on('mousedown', lHandlers.mousedown).on('click', lHandlers.click).on('dblclick', lHandlers.dblclick);
    }
};

function _clearSelection() {
    try {
        if (document.selection && document.selection.empty) {
            document.selection.empty();
        } else if (window.getSelection) {
            var sel = window.getSelection();
            if (sel && sel.removeAllRanges) sel.removeAllRanges();
        }
    } catch (e) { }
};


(function ($) {
    $.fn.hasVerticalScrollbar = function () {
        return this.get(0).clientHeight < this.get(0).scrollHeight;
    };
})(jQuery);

(function ($) {
    $.fn.hasHorizontalScrollbar = function () {
        return this.get(0).clientWidth < this.get(0).scrollWidth;
    };
})(jQuery);

$.postify = function (value) {
    var result = {};

    var buildResult = function (object, prefix) {
        for (var key in object) {

            var postKey = isFinite(key)
                ? (prefix != "" ? prefix : "") + "[" + key + "]"
                : (prefix != "" ? prefix + "." : "") + key;

            switch (typeof (object[key])) {
                case "number": case "string": case "boolean":
                    result[postKey] = object[key];
                    break;

                case "object":
                    if (object[key].toUTCString)
                        result[postKey] = object[key].toUTCString().replace("UTC", "GMT");
                    else {
                        buildResult(object[key], postKey != "" ? postKey : key);
                    }
            }
        }
    };

    buildResult(value, "");

    return result;
};

$.fn.htSecurityLookup = function (pUrl, pDestSelector, options) {
    //options.LabelCallback func(item) return display row;
    //options.valueCallback func(item) return display text for selection;
    //options.selectCallback  func(event,ui)
    //options.matchOnCode:  Matches the typed text and selects the matching code on the list
    //options.mustBeInList: Leave the value as typed even if no match on code.
    //options.showWatermark: Show or hide watermark.
    //options.selectItemHtml: func(item)  returns select item html for autocomplete, filled with actiual data
    options = $.extend({ matchOnCode: true, mustBeInList: true, showWatermark: true, inFocusItem: false }, options);
    var lIsMatchOnCode = options.matchOnCode;
    var lMustBeInList = options.mustBeInList;

    var lData = null;
    var lVal = null;
    var lIsSel = false;
    var lInFocus = false;
    var lInFocusItem = options.inFocusItem;
    var lSelector = $(this);
    var total = options.total || 10;
    var sourceUrl = pUrl;

    if (pUrl == 'destroy') {
        $(this).unbind('focusin');
        $(this).unbind('focusout');
        return $(this).autocomplete('destroy');
    }
    if (options.showWatermark) {
        $(this).watermark();
    }
    $(this).focusin(function () {
        if (!lInFocus) {
            lIsSel = false;
            lVal = $(this).val();
        }
        lInFocus = true;
    });

    $(this).focusout(function (e) {
        if (lInFocusItem) {
            lInFocus = false;
            return;
        }

        var lCurVal = $(this).val();
        if (lCurVal == "") return;

        if (!lIsSel && lIsMatchOnCode) {
            $(lData).each(function (i, e) {
                if (lCurVal.toUpperCase() == e.code.toUpperCase()) {
                    lFuncSelect(e, { item: e });
                    return false;
                }
            });
        }

        if (lMustBeInList || lVal != "")
            $(this).val(lVal);
        lInFocus = false;
    });

    var lFuncSelect = function (event, ui) {
        if (pDestSelector) $(pDestSelector).val(ui.item.item.id);
        lVal = ui.item.value;
        lIsSel = true;
        if (options.selectCallback) return options.selectCallback(event, ui);
    };

    var securityLabelMapping = {
        "SecurityIssuerCode": "Issuer Code",
        "SecurityIssuerDesc": "Issuer Desc",
        "SecurityCode": "Security Code",
        "SecurityDesc": "Security Desc"
    };

    var getItemHtml = function (item, term) {
        if (options.selectItemHtml)
            return options.selectItemHtml(item);

        var itemCodes = [];
        var itemValues = [];
        item.matches.forEach(function (match) {
            itemCodes.push((securityLabelMapping[match.name] || match.name) + ': ' + highLight(match.value, term));
            itemValues.push((securityLabelMapping[match.name] || match.name) + ': ' + match.value);
        });

        var itemCode = itemCodes.join(', ');
        var matchTitle = itemValues.join(', ');

        var $html = $('<table>', {
            'html' : $('<tr>', {
                'html': [
                    $('<td>', { 'css': { 'width': '50px', 'text-align': 'right', 'padding-right':'5px' }, 'text': item.type  }),
                    $('<td>', { 'title': matchTitle, 'html': itemCode, 'css': { 'width': 'auto', 'max-width': '220px' } })]
            })
        });

        return $html.prop('outerHTML');
    };

    var highLight = function (result, term) {
        var re = new RegExp(term, 'gi');
        return result.replace(re, function (str) { return '<span style="font-weight:bold">' + str + '</span>' });
    }

    return $(this).autocomplete({
        html: true,
        delay: 50,
        open: function (event, ui) {
            var maxItem = 0;
            $.each($(".ui-autocomplete table"), function (key, item) {
                var itemLength = $(item).text().trim().length;
                maxItem = Math.max(itemLength, maxItem);
            });

            var maxWidth = Math.min(maxItem * 7, 1000) + 'px';
            $(".ui-autocomplete").css("width", maxWidth);
        },
        appendTo: "body",//Seems to make sense for all 'autocomplete' elements
        source: function (request, response) {
            var postData = { text: request.term, total: total, includeSLM: 1 }
              $.extend(true, postData, options.postData);
            $.ajax({
                url: sourceUrl,
                dataType: "json",
                data: postData,
                type: "POST",
                success: function (data) {
                    var lData = [];
                    data.forEach(function (item) {
                        var html = getItemHtml(item, request.term);
                        lData.push({
                            value: item.matches[0].value,
                            label: html,
                            code: item.id,
                            item: item,
                            extras: item.extras,
                            name: item.matches[0].name,
                            type: item.type
                        });
                    });
                    response(lData);
                }
            })
        },
        minLength: 2,
        focus: function (event, ui) //fires after selection
        {
            this.value = ui.item.value;
        },
        close: function (event, ui) //fires after selection
        {
            lInFocusItem = options.inFocusItem;
        },
        select: lFuncSelect
    }).bind("focus", function () {//shows all list item on focus
        if (this.value == '' || this.value == ' ') {
            $(this).autocomplete("search", "");
        }
    });

};
